export const PaymentButton = ({ onClick, label, disabled = false }) => {
    return (
      <div class="flex justify-center items-center">
        <button
          class="bg-[#32ac91] text-white font-bold py-2 px-16 rounded hover:bg-[#47B49C] text-2xl"
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </button>
      </div>
    );
  };