import { IonIcon } from "@ionic/react";
import { lockClosed } from "ionicons/icons";

export const PaymentCgu = () => {
  return (
    <div class="mt-auto text-gray-400 bottom-0   p-4 space-y-4 -ml-5">
      <div class="font-bold text-xs">
        <IonIcon slot="icon-only" icon={lockClosed}  size="medium" />
        CONNEXION A VOTRE BANQUE SECURISEE PAR<span className="uppercase text-black"> CASHLEE & Perspecteev</span> 
      </div>
      <div class="text-xs">
        En continuant vous acceptez les CGU et vous autorisez Cashlee (DIGI
        INNOVA) à initier un virement via son partenaire Perspecteev qui est
        agréée sous le numéro 16918 par l’ACPR. Les CGU sont consultables{" "}
        <span class="underline">
          <a href="https://cashlee.eu/cgu-particuliers">ici.</a>
        </span>
      </div>
    </div>
  );
};
